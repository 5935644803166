import React from "react"
import Layout from "../components/layouts/Default"
import Container from "../components/layouts/container/Container"

const NotFound = ({ data, location }) => {
  return (
    <Layout location={location} title="Seite nicht gefunden" desc="">
      <Container className="my-8 mmd:my-16">
        <div className="text-lg max-w-prose mx-auto">
          <div className="block text-base text-center text-gray-700 font-semibold tracking-wide uppercase">
            404
          </div>
          <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 mb-3 sm:text-4xl">
            Seite nicht gefunden
          </h1>

          <div className="mt-3 text-xl text-gray-500 leading-8 prose text-center mb-4">
            <p>Ups! Da hat jemand die Seite verschleppt!</p>
          </div>
          <div>
            <svg
              data-name="Layer 1"
              width={672.5315}
              height={738.39398}
              viewBox="0 0 672.5315 738.39398"
              className="w-full max-w-md mx-auto h-auto"
            >
              <path
                d="M730.734 230.607c-46.62-7.44-99.71-11.41-155-11.41-50.6 0-99.35 3.32-142.98 9.58.01-.67.02-1.34.05-2.01a149 149 0 01297.91 1.82c.01.68.02 1.35.02 2.02z"
                transform="translate(-263.734 -80.803)"
              />
              <path
                d="M601.285 82.144a149.047 149.047 0 00-168.932 143.229c-.03.67-.04 1.34-.05 2.01 12.962-1.86 26.385-3.454 40.166-4.784A149.022 149.022 0 01601.285 82.144z"
                transform="translate(-263.734 -80.803)"
                fill="#fff"
                opacity={0.1}
              />
              <path
                d="M795.914 242.427a600.121 600.121 0 00-65.2-13.84 943.364 943.364 0 00-108.74-10.45 1133.608 1133.608 0 00-83.01-.34 973.29 973.29 0 00-106.16 8.97 624.292 624.292 0 00-77.25 15.66c-59.21 16.37-91.82 38.31-91.82 61.77s32.61 45.4 91.82 61.77c41.64 11.52 92.98 19.37 148.92 22.97 23.09 1.5 46.96 2.26 71.26 2.26 24.38 0 48.33-.77 71.49-2.27 50.91-3.29 98.01-10.1 137.43-20 .21-.06.41-.11.62-.16 2.66-.66 5.28-1.35 7.87-2.04.93-.26 1.85-.51 2.77-.76a.978.978 0 01.16-.05c.88-.24 1.75-.49 2.62-.73 1.74-.5 3.46-.99 5.15-1.5.08-.02.15-.04.22-.06 1.47-.44 2.91-.88 4.34-1.32 1.17-.37 2.33-.73 3.48-1.1q1.26-.405 2.49-.81c.6-.2 1.19-.39 1.77-.59.79-.26 1.58-.53 2.36-.8.33-.11.66-.22.98-.34.75-.25 1.48-.51 2.21-.77.79-.28 1.58-.57 2.36-.85.65-.23 1.3-.47 1.94-.71.54-.21 1.07-.41 1.61-.61 1.47-.55 2.91-1.12 4.33-1.68.71-.29 1.42-.57 2.12-.86.69-.28 1.39-.57 2.07-.86q1.68-.705 3.3-1.41c.52-.24 1.05-.47 1.56-.69.39-.18.77-.35 1.16-.53.28-.12.56-.25.83-.38 1.01-.46 2.01-.93 2.99-1.4q5.64-2.7 10.53-5.52c20.45-11.71 31.24-24.7 31.24-38.2 0-23.46-32.61-45.4-91.82-61.77zm-.54 121.62c-41.69 11.53-93.17 19.38-149.26 22.95-22.81 1.45-46.39 2.2-70.38 2.2-23.91 0-47.41-.74-70.15-2.19-56.18-3.56-107.74-11.41-149.49-22.96-58.27-16.12-90.36-37.37-90.36-59.85a24.118 24.118 0 01.74-5.89c5.1-20.28 36.47-39.26 89.62-53.96a623.806 623.806 0 0176.66-15.57 976.027 976.027 0 01106.8-9q17.88-.585 36.18-.58c15.41 0 30.65.31 45.63.91a941.367 941.367 0 01109.37 10.5 598.858 598.858 0 0164.64 13.74c53.14 14.7 84.5 33.67 89.61 53.94a23.822 23.822 0 01.75 5.91c0 22.48-32.09 43.73-90.36 59.85z"
                transform="translate(-263.734 -80.803)"
                fill="#3f3d56"
              />
              <path
                d="M887.164 305.107c0 13.36-11.01 26-30.67 37.29-3.27 1.88-6.79 3.72-10.53 5.52-.98.47-1.98.94-2.99 1.4-.27.13-.55.26-.83.38-.39.18-.77.35-1.16.53-.51.22-1.04.45-1.56.69q-1.62.705-3.3 1.41c-.68.29-1.38.58-2.07.86-.7.29-1.41.57-2.12.86-1.42.56-2.86 1.13-4.33 1.68-.54.2-1.07.4-1.61.61-.64.24-1.29.48-1.94.71-.78.28-1.57.57-2.36.85-.73.26-1.46.52-2.21.77-.32.12-.65.23-.98.34-.78.27-1.57.54-2.36.8-.58.2-1.17.39-1.77.59q-1.23.405-2.49.81c-1.15.37-2.31.73-3.48 1.1-1.43.44-2.87.88-4.34 1.32-.07.02-.14.04-.22.06-1.69.51-3.41 1-5.15 1.5-.87.24-1.74.49-2.62.73a.978.978 0 00-.16.05c-.92.25-1.84.5-2.77.76-2.58.68-5.21 1.37-7.87 2.04-.21.05-.41.1-.62.16-38.35 9.58-85.4 16.56-137.47 19.93-22.81 1.47-46.59 2.25-71.02 2.25-24.65 0-48.63-.79-71.62-2.29-137.24-8.95-239.38-43.03-239.38-83.71a25.072 25.072 0 011.12-7.3c.06.17.12.33.19.5 14.27 37.48 115.54 67.77 246.94 75.16 20.13 1.14 40.98 1.73 62.32 1.73 21.43 0 42.36-.6 62.57-1.74 131.29-7.42 232.46-37.72 246.68-75.17q.36-.9.63-1.8a25.305 25.305 0 011.55 8.62zM355.404 294.343c-16.643 0-34.331-3.58-34.331-10.217s17.688-10.217 34.33-10.217 34.331 3.581 34.331 10.217-17.688 10.217-34.33 10.217zm0-18.434c-19.053 0-32.331 4.331-32.331 8.217s13.278 8.217 32.33 8.217 32.331-4.33 32.331-8.217-13.277-8.217-32.33-8.217z"
                transform="translate(-263.734 -80.803)"
                fill="#3f3d56"
              />
              <path
                d="M426.403 341.343c-16.642 0-34.33-3.58-34.33-10.217s17.688-10.217 34.33-10.217c16.643 0 34.331 3.581 34.331 10.217s-17.688 10.217-34.33 10.217zm0-18.434c-19.052 0-32.33 4.331-32.33 8.217s13.278 8.217 32.33 8.217 32.331-4.33 32.331-8.217-13.278-8.217-32.33-8.217zM795.403 294.343c-16.642 0-34.33-3.58-34.33-10.217s17.688-10.217 34.33-10.217c16.643 0 34.331 3.581 34.331 10.217s-17.688 10.217-34.33 10.217zm0-18.434c-19.053 0-32.33 4.331-32.33 8.217s13.277 8.217 32.33 8.217 32.331-4.33 32.331-8.217-13.278-8.217-32.33-8.217zM724.403 341.343c-16.642 0-34.33-3.58-34.33-10.217s17.688-10.217 34.33-10.217c16.643 0 34.331 3.581 34.331 10.217s-17.688 10.217-34.33 10.217zm0-18.434c-19.053 0-32.33 4.331-32.33 8.217s13.277 8.217 32.33 8.217 32.331-4.33 32.331-8.217-13.278-8.217-32.33-8.217zM575.403 363.343c-16.642 0-34.33-3.58-34.33-10.217s17.688-10.217 34.33-10.217c16.643 0 34.331 3.581 34.331 10.217s-17.688 10.217-34.33 10.217zm0-18.434c-19.053 0-32.33 4.331-32.33 8.217s13.277 8.217 32.33 8.217 32.331-4.33 32.331-8.217-13.278-8.217-32.33-8.217z"
                transform="translate(-263.734 -80.803)"
                fill="#3f3d56"
              />
              <circle
                cx={336.97785}
                cy={450.70425}
                r={42.01233}
                fill="#2f2e41"
              />
              <path
                transform="rotate(-26.601 274.6 1087.35)"
                fill="#2f2e41"
                d="M565.93651 563.55388H588.80407V576.3171600000001H565.93651z"
              />
              <ellipse
                cx={563.82041}
                cy={573.74843}
                rx={3.98853}
                ry={10.63605}
                transform="rotate(-56.601 356.921 778.245)"
                fill="#2f2e41"
              />
              <path
                transform="rotate(-63.399 426.77 743.05)"
                fill="#2f2e41"
                d="M617.67227 558.50174H630.43555V581.3693000000001H617.67227z"
              />
              <ellipse
                cx={637.60379}
                cy={573.74843}
                rx={10.63605}
                ry={3.98853}
                transform="rotate(-33.399 371.066 972.9)"
                fill="#2f2e41"
              />
              <circle cx={334.03663} cy={440.42779} r={14.35864} fill="#fff" />
              <ellipse
                cx={597.86951}
                cy={515.08401}
                rx={4.76624}
                ry={4.8}
                transform="rotate(-45 368.465 793.038)"
                fill="#3f3d56"
              />
              <path
                d="M633.854 485.802c.632-15.553-12.773-28.727-29.94-29.425s-31.598 11.346-32.23 26.9 11.302 19.087 28.47 19.785 33.068-1.706 33.7-17.26z"
                transform="translate(-263.734 -80.803)"
              />
              <ellipse
                cx={644.38811}
                cy={537.56776}
                rx={6.59448}
                ry={21.00616}
                transform="rotate(-40.645 403.434 853.218)"
                fill="#2f2e41"
              />
              <ellipse
                cx={557.15365}
                cy={537.56776}
                rx={21.00616}
                ry={6.59448}
                transform="rotate(-49.355 337.356 784.164)"
                fill="#2f2e41"
              />
              <path
                d="M612.25 548.064a9.572 9.572 0 01-18.835 3.429l-.003-.019c-.942-5.202 3.08-7.043 8.283-7.985s9.614-.627 10.556 4.575z"
                transform="translate(-263.734 -80.803)"
                fill="#fff"
              />
              <path
                d="M529.734 576.197a2 2 0 01-2-2v-118a2 2 0 014 0v118a2 2 0 01-2 2zM499.734 682.197a2 2 0 01-2-2v-86a2 2 0 114 0v86a2 2 0 01-2 2zM576.734 611.197a2 2 0 01-2-2v-118a2 2 0 114 0v118a2 2 0 01-2 2zM547.734 696.197a2 2 0 01-2-2v-48a2 2 0 114 0v48a2 2 0 01-2 2zM588.734 450.197a2 2 0 01-2-2v-48a2 2 0 014 0v48a2 2 0 01-2 2zM488.734 471.197a2 2 0 01-2-2v-48a2 2 0 014 0v48a2 2 0 01-2 2zM662.734 476.197a2 2 0 01-2-2v-48a2 2 0 114 0v48a2 2 0 01-2 2zM658.734 626.197a2 2 0 01-2-2v-58a2 2 0 114 0v58a2 2 0 01-2 2zM618.734 677.197a2 2 0 01-2-2v-86a2 2 0 114 0v86a2 2 0 01-2 2zM626.734 530.197a2 2 0 01-2-2v-118a2 2 0 114 0v118a2 2 0 01-2 2z"
                transform="translate(-263.734 -80.803)"
                fill="#ccc"
              />
              <ellipse
                cx={858.10361}
                cy={764.78333}
                rx={6.76007}
                ry={21.53369}
                transform="rotate(-39.938 615.048 1087.291)"
                fill="#2f2e41"
              />
              <circle
                cx={812.2965}
                cy={757.30544}
                r={43.06733}
                transform="rotate(-71.565 624.375 899.86)"
                fill="#2f2e41"
              />
              <path
                fill="#2f2e41"
                d="M553.7073 710.30199H566.7910400000001V733.7437H553.7073z"
              />
              <path
                fill="#2f2e41"
                d="M527.53982 710.30199H540.62356V733.7437H527.53982z"
              />
              <ellipse
                cx={555.8879}
                cy={734.01629}
                rx={10.90314}
                ry={4.08868}
                fill="#2f2e41"
              />
              <ellipse
                cx={529.72042}
                cy={733.47115}
                rx={10.90314}
                ry={4.08868}
                fill="#2f2e41"
              />
              <path
                d="M798.774 703.169c3.845-15.487 20.82-24.601 37.914-20.357s27.835 20.24 23.99 35.728-16.605 15.537-33.699 11.292-32.05-11.176-28.205-26.663z"
                transform="translate(-263.734 -80.803)"
                fill="#ccc"
              />
              <ellipse
                cx={763.7883}
                cy={737.32189}
                rx={6.76007}
                ry={21.53369}
                transform="rotate(-64.626 568.044 905.41)"
                fill="#2f2e41"
              />
              <circle cx={542.12366} cy={667.41487} r={14.35864} fill="#fff" />
              <circle
                cx={536.22229}
                cy={662.26808}
                r={4.78622}
                fill="#3f3d56"
              />
              <circle cx={542} cy={697.39398} r={6} fill="#fff" />
              <path
                d="M935.266 819.197h-236a1 1 0 010-2h236a1 1 0 010 2z"
                transform="translate(-263.734 -80.803)"
                fill="#3f3d56"
              />
            </svg>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFound
